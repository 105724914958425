<!--
 * @Author: your name
 * @Date: 2021-07-29 15:37:05
 * @LastEditTime: 2021-08-10 14:47:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\news\components\links.vue
-->
<template>
  <div class="links">
    <div class="linkTitle">热门资讯</div>
    <p v-for="(item,index) in list" :key="index" @click="$router.push({ name: 'newsDetail',query:{id:item.id} })">
      {{item.title}}
    </p>
    
  </div>
</template>
<script>
import { getHotNews } from "@/api/index";
export default {
  data() {
    return {
      list:{}
    }
  },
  methods: {
    getList() {
      getHotNews().then((res) => {
        this.list=res.data
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.links {
  width: 294px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 13px 23px;
  .linkTitle {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff6e6e;
  }
  p {
    cursor: pointer;
    margin: 5px 0;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f4f4f;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:hover {
      color: #000;
    }
  }
}
</style>
<!--
 * @Author: your name
 * @Date: 2021-07-29 11:05:20
 * @LastEditTime: 2021-08-27 10:32:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\news\index.vue
-->
<template>
  <div class="news">
    <div class="container">
      <ul class="tabs flex-h flex-vc">
        <li
          v-for="(item, index) in tabs"
          :key="index"
          :class="{ active: index == currentIndex }"
          @click="
            currPage = 1;
            getList(item.id, index);
          "
        >
          {{ item.typeName }}
        </li>
      </ul>
      <links style="float: right" />
      <ul class="newsList">
        <li
          class="flex-h"
          v-for="(item, index) in tableData"
          :key="index"
          @click="$router.push({ name: 'newsDetail', query: { id: item.id } })"
        >
          <div class="itemLeft">
            <img class="newsImg" :src="item.coverUrl" />
          </div>
          <div class="itemRight">
            <h1>
              {{ item.title }}
            </h1>
            <p>
              {{ item.synopsis }}
            </p>
            <div class="nums flex-h flex-vc">
              <img src="@/assets/img/eye.png" />
              <span>{{ item.browseNum }}人查看</span>
              <img src="@/assets/img/time.png" />
              <span>{{ $formatDate(item.releaseTime) }}</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="Pagination">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-size="limit"
          layout=" prev, pager,  next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import links from "./components/links.vue";
import { getNewsType, getNewsList } from "@/api/index";
export default {
  data() {
    return {
      tabs: [],
      typeId: "",
      currentIndex: 0,
      limit: 10,
      total: 0,
      currPage: 1,
      tableData: [],
    };
  },
  components: { links },
  methods: {
    getTabs() {
      getNewsType().then((res) => {
        this.tabs = res.data.list;
        this.getList(this.tabs[0].id, 0);
        let typeId = this.$route.query.typeId;
        if (typeId) {
          this.tabs.forEach((item, index) => {
            if (item.id == typeId) {
              this.getList(typeId, index);
            }
          });
        }
      });
    },
    getList(id, index) {
      this.currentIndex = index;
      this.typeId = id;
      getNewsList({
        currPage: this.currPage,
        pageLimit: this.limit,
        typeId: id,
      }).then((res) => {
        console.log(res);
        this.tableData = res.data.list;
        this.total = res.data.totalCount;
      });
    },

    handleCurrentChange(val) {
      this.currPage = val;
      this.getList(this.typeId, this.currentIndex);
    },
  },
  created() {
    this.getTabs();
  },
};
</script>
<style lang="scss" scoped>
li {
  list-style-type: none
}
    .news {
        background: #f6f6f6;
        padding-top: 25px;
        padding-bottom: 25px;
        min-height: 500px;

        .container {
            .tabs {
                overflow-x: auto;
                height: 78px;
                background: #ffffff;
                border-radius: 8px;
                white-space: nowrap;
                margin-bottom: 16px;

                li {
                    cursor: pointer;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #808080;
                    margin: 0 20px;

                    &.active {
                        font-size: 24px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #ff6e6e;
                    }
                }
            }

            .newsList {
                flex-wrap: wrap;

                li {
                    cursor: pointer;
                    width: 887px;
                    height: 220px;
                    background: #ffffff;
                    border-radius: 8px;
                    margin-bottom: 20px;
                    box-sizing: border-box;
                    padding-left: 36px;

                    .itemLeft {
                        padding-top: 22px;

                        .newsImg {
                            width: 260px;
                            height: 173px;
                        }

                        .startList {
                            margin-top: 29px;

                            img {
                                width: 20px;
                                margin: 0 5px;
                            }
                        }
                    }

                    .itemRight {
                        padding-top: 26px;
                        margin-left: 35px;

                        h1 {
                            width: 533px;
                            font-size: 22px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 900;
                            color: #0c0c0c;
                            margin-bottom: 16px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        p {
                            width: 533px;
                            height: 70px;
                            margin-bottom: 28px;
                            font-size: 18px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #808080;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }

                        .tags {
                            font-size: 20px;
                            width: 352px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #737373;

                            span {
                                display: inline-block;
                                height: 31px;
                                font-size: 20px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #797979;
                                line-height: 31px;
                                text-align: center;
                                background: #dbd7d7;
                                padding: 0 14px;
                                margin-bottom: 9px;
                                margin-right: 14px;
                            }
                        }

                        .nums {
                            img {
                                width: 20px;
                                margin-right: 2px;
                            }

                            span {
                                font-size: 14px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-weight: 400;
                                color: #1a1a1a;
                                margin-right: 20px;
                            }
                        }
                    }
                }
            }

            .Pagination {
                text-align: center;
            }
        }
    }
</style>